import { Component } from "react";
import "./AdminLogin.scss";
import loginimage from"../../res/images/loginpage.jpg";
import $ from "jquery";

class AdminLogin extends Component{
    constructor(props){
        super(props);
        this.state={
            username:"",
            password:""
        };
        this.update_form=this.update_form.bind(this);
        this.check_credentials=this.check_credentials.bind(this);
        // this.username_update=this.username_update.bind(this);
    }
    update_form(e){
        this.setState({
          [e.target.name]:e.target.value
        })
    }
    password_update(e){
        this.setState({
          password:e.target.value  
        })
    }
    check_credentials(){
        // console.log(this.state.username);
        const obj_this=this;
        $.ajax({
            url:process.env.REACT_APP_SSS + "/login.php",
            data:{
                username : this.state.username,
                password : this.state.password,
            },
            method:"POST"
        }).done(function(return_data){
            // console.log(return_data);
            try{

                return_data=JSON.parse(return_data);
            }catch(err){
                console.log(return_data);
                return;
            }
            if(return_data.status=="success"){
                // alert("Login Success");
                obj_this.props.history.push("/Enquiries");
            }
            else{
                alert("Login Failed");
            }
        })
    }


    render(){
        return(
            <div className="login_page_container">
                <div className="login_container">
                    <div className="grid_container">
                        <div className="image_container" style={{backgroundImage:"url('"+loginimage+"')"}}></div>
                        <div className="text_block">
                            {/* <div className="text_container"> */}
                                <div className="signin">Sign In</div>
                                <div className="input_label">Username</div>
                                <input className="input_fields" placeholder="Enter Your Username" type="text" name="username" value={this.state.username} onChange={this.update_form}></input>
                                <div className="input_label">Password</div>
                                <input className="input_fields" placeholder="Enter Your Password" type="password" name="password" value={this.state.password} onChange={this.update_form}></input>
                                <div className="signin_button" onClick={this.check_credentials}>Sign in</div>

                                
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AdminLogin;