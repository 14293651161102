import { BrowserRouter, Route } from 'react-router-dom';
import "./res/react-styler/styler.scss";
import MyProvider from "./components/MyProvider.jsx";

// import Home from './components/Home/Home.jsx';
// import AboutUs from './components/AboutUs/AboutUs.jsx';
// import CustomAlert from "./components/CustomAlert/CustomAlert.jsx";
// import Loading from './components/Loading/Loading';
// import Footer from './components/Footer/Footer';
// import Header from './components/Header/Header';
// import ContactUs from './components/ContactUs/ContactUs';
import ScrollToTop from './ScrollToTop';
// import WhatsappChatBox from './components/WhatsappChatBox/WhatsappChatBox';
import Enquiries from './components/Enquiries/Enquiries';
import ViewEnquiry from './components/ViewEnquiry/ViewEnquiry';
import AdminLogin from './components/AdminLogin/AdminLogin';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import ImageUpload from './components/ImageUpload/ImageUpload';
import Gallery from './components/Gallery/Gallery';



function App() {
  return (
    <MyProvider>
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Route exact path="/ViewEnquiry/:enquiry_id" component={ViewEnquiry} />
        <Route exact path="/Enquiries" component={Enquiries} />
        <Route exact path="/ImageUpload" component={ImageUpload} />
        <Route exact path="/Gallery" component={Gallery} />
        <Route path="/" component={Header} />
        <Route exact path="/" component={AdminLogin} />
        {/* <Route exact path="/floorlist" component={FloorList} />
        <Route exact path="/roomlist" component={RoomList} />
        <Route exact path="/appliances" component={Appliances} /> */}
        {/* <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/Home" component={Home} />
        <Route path="/" component={CustomAlert} />  
        <Route path="/" component={Loading}/>
        <Route path="/" component={Footer}/>
        <Route path="/" component={Header}/>
        <Route path="/" component={WhatsappChatBox} /> */}
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;
