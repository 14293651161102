import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Gallery.scss";
// import sample from "../../res/images/sampleimg.jpg";
import $ from "jquery";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

class Gallery extends Component{
  constructor(props){
    super(props);
    this.state={
        gallery_images:[],
        delete_popup_visibility:"none",
        loading_popup_visibility:"none"
    };
    this.image_to_be_deleted="";
    this.deleteImage=this.deleteImage.bind(this);
    this.showDeletePopup=this.showDeletePopup.bind(this);
    this.hideDeletePopup=this.hideDeletePopup.bind(this);
  };
  componentDidMount(){
    this.setState({
      loading_popup_visibility:"block"
    });
    const obj_this=this;
    $.ajax({
        url:process.env.REACT_APP_SSS + "/fetch_image.php"

    }).done(function(return_data){

        return_data=JSON.parse(return_data)      
        console.log(return_data);

        obj_this.setState({
            gallery_images:return_data,
            loading_popup_visibility:"none"
        })
    });
  }
  showDeletePopup(imageID){
    this.setState({
      delete_popup_visibility:"block"
    });
    this.image_to_be_deleted=imageID;
  }
  hideDeletePopup(){
    this.setState({
      delete_popup_visibility:"none"
    });
    this.image_to_be_deleted="";
  }

  deleteImage(){
    this.setState({
      delete_popup_visibility:"none",
      loading_popup_visibility:"block"
    });
    const obj_this=this;

    // alert(image_id);
    $.ajax({
      url:process.env.REACT_APP_SSS + "/delete_image.php",
      method:"POST",
      data:{
        image_id:this.image_to_be_deleted
      }

    }).done(function(return_data){
      try{
        return_data=JSON.parse(return_data)
      }catch(e){
        console.log(return_data);
        return;
      }
      alert("Successfully Deleted Image");
      obj_this.setState({
      loading_popup_visibility:"none"
      });
      var idx;
      var i=0;
      while(i<obj_this.state.gallery_images.length){

        if(obj_this.image_to_be_deleted==obj_this.state.gallery_images[i]["Image ID"]){
          idx=i;
          break;
        }
        i++;
      }
      // for(var i=o;i<this.state.gallery_images.length;i++){
      //   if(image_id==this.state.gallery_images[i]["Image ID"]){
      //     idx=i;
      //     return;
      //   }
      
      // }
  
      obj_this.state.gallery_images.splice(idx,1);
      // console.log(obj_this.state.gallery_images);
      obj_this.setState({
        gallery_images:obj_this.state.gallery_images
      },
      // function(){
      //   console.log(obj_this.state.gallery_images);
      // }
      );

    });
  }

  render(){
    return(
    <div className="gallery_page_container">
      <div className="gallery_block">
        {this.state.gallery_images.map((image,idx)=>(
        <div key={idx} className="image_container">
          <img src={process.env.REACT_APP_CDN_URL+"/"+image["Image ID"]+".jpg"} className="image_tile"></img>
          <button 
          className="delete_button" 
          // onClick={this.deleteImage}
          // onClick={()=>{
          //   alert("hi");
          // }}
          onClick={()=>{
            // console.log("hi");
            // this.deleteImage(image["Image ID"]);
            this.showDeletePopup(image["Image ID"]);
          }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
            {/* Delete */}
          </button>
          <div className="image_description">{image.Description}</div>
        </div>
        ))}
        {/* <div className="image_container">
          <img src={sample} className="image_tile"></img>
          <div className="image_description">gcfghdfsfs</div>
        </div> */}
      </div>
      <div className="delete_alert_page_container" style={{display:this.state.delete_popup_visibility}}>
        <div className="delete_alert_container" >
          <div className="confirm_question">Are you sure?</div>
          <div className="confirm_answer">
            <div className="no" onClick={this.hideDeletePopup}>No</div>
            <div className="yes" onClick={this.deleteImage} >Yes</div>
          </div>
        </div>
      </div>
      <div className="loading_page_container" style={{display:this.state.loading_popup_visibility}}>
        <div className="loading_container">
            <div className="lctc" style={{ position: "fixed" }}>
              <svg
                width="69px"
                height="69px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
                style={{
                  shapeRendering: "auto",
                  animationPlayState: "running",
                  animationDelay: "0s"
                }}
              >
                <circle
                  cx={50}
                  cy={50}
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#6e6e6e"
                  strokeWidth={3}
                  r={35}
                  strokeDasharray="164.93361431346415 56.97787143782138"
                  style={{ animationPlayState: "running", animationDelay: "0s" }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="0.3s"
                    begin="0s"
                    repeatCount="indefinite"
                    style={{ animationPlayState: "running", animationDelay: "0s" }}
                  />
                </circle>
              </svg>
            </div>
        </div>
      </div>
    </div>
    ) 
  }
}
export default Gallery;