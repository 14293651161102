import { Component } from "react";
import "./Sidebar.scss";


class Sidebar extends Component{
    render(){
        return(
         <div className="sidebar_container"></div>   
        )
    }
}
export default Sidebar;