import { Component } from "react";
import "./Header.scss";
import logoimage from "../../res/images/Logo_blue.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { faBars } from "@fortawesome/free-solid-svg-icons";


class Header extends Component{
  constructor (props){
    super(props);
    this.sidebar_status="closed";
    this.sidebar_control=this.sidebar_control.bind(this)
  }
  sidebar_control(){
    if (this.sidebar_status=="closed"){
      $(".sidebar_block").css({
        "transform":"translate(0%,0%)"
      });
      this.sidebar_status="open";
    }
    else{
      $(".sidebar_block").css({
        "transform":"translate(-100%,0%)"
      }); 
      this.sidebar_status="closed";
    }
  }


  render(){
    return(
      <>
        <div className="header_container">
          <div className="hamburger" onClick={this.sidebar_control} >
            {/* <FontAwesomeIcon icon={faBars} /> */}
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div   className="logo_container" style={{backgroundImage:"url('"+logoimage+"')"}}>
          </div>
        </div>
        
        <div className="sidebar_block">
          <div className="logo_closebutton_container">
            {/* <div className="logo" style={{backgroundImage:"url('"+logoimage+"')"}}></div>
            <div></div>
              {/* <div className="close_button">
                <FontAwesomeIcon icon={faSquareXmark} />
              </div> */} 
          </div>
          <div className="sidebar_items_container">
            <Link to="/ImageUpload" className="sidebar_item" onClick={this.sidebar_control}>Upload Image</Link>
            {/* <div className="sidebar_item">item 2</div> */}
            <Link to="/Enquiries" className="sidebar_item" onClick={this.sidebar_control}>View Enquiries</Link>
            <Link to="/Gallery" className="sidebar_item" onClick={this.sidebar_control}>Gallery</Link>
            <Link to="/" className="sidebar_item" onClick={this.sidebar_control}>Logout</Link>
          </div>
        </div>
      </>
    )
  }
}
export default Header;