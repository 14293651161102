import { Component } from "react";
import "./ViewEnquiry.scss";
import $ from "jquery";

class ViewEnquiry extends Component{
    constructor(props){
        super(props);
        this.state={
            firstname:"",
            lastname:"",
            date:"",
            phone:"",
            email:"",
            message:"",
            date:""
        };
        this.enquiry_id=this.props.match.params.enquiry_id;
    }
    componentDidMount(){
        const obj_this=this;
        $.ajax({
            url:process.env.REACT_APP_SSS + "/fetch_enquiry_details.php",
            data:{
                enquiry_id:this.enquiry_id
            },
            method:"POST"
        }).done(function(return_data){
            // console.log(return_data);
            try{
                return_data=JSON.parse(return_data);
            }
            catch(err){
                console.log(return_data);
                return;
            }
            // console.log(return_data.Firstname);
            obj_this.setState({
                firstname:return_data.Firstname,
                lastname:return_data.Lastname,
                phone:return_data.Phone,
                email:return_data.Email,
                message:return_data.Message,
                date:return_data.Timestamp
            })
        });

    }
    render(){
        return(
            <div className="view_enquiry_page_container">
                <div className="enquiry_details_container">
                    <div className="name">{this.state.firstname}</div>
                    <div className="name">{this.state.lastname}</div>
                    <div className="name">{this.state.phone}</div>
                    <div className="name">{this.state.date}</div>
                    <div className="name">{this.state.email}</div>   
                    <div className="name">{this.state.message}</div>   
                </div>
            </div>
        )
    }
}
export default ViewEnquiry;