import React, { Component } from "react";
import MyContext from './MyContext.jsx';

export default class MyProvider extends Component {
  constructor(props){
    super(props);
    var session = window.localStorage.getItem('session_fat_loss_with_arun_admin_app');
    session = session?JSON.parse(session):"";
    this.state = {
      version : "1.0.0",
      session : session,
      show_loading : 0,
    };
    this.bind_session = this.bind_session.bind(this);
    this.validate_XHR_return_data = this.validate_XHR_return_data.bind(this);
  }

  bind_session = function(data){
    if(data instanceof FormData){
      data.append("sid",this.state.session.sid);
      data.append("token",this.state.session.token);
    }else if(typeof data == "object"){
      data.sid = this.state.session.sid;
      data.token = this.state.session.token;
    }else if(typeof data == "string"){
      var arr= data.split("?");
      if (arr.length == 2){
        data = data+"&sid="+this.state.session.sid+"&token="+this.state.session.token
      }else{
        data = data+"?sid="+this.state.session.sid+"&token="+this.state.session.token
      }
    }
    return data;
  }
  validate_XHR_return_data = function(return_data){
    try{
      return_data = JSON.parse(return_data);
      // console.log(return_data);
    }catch(err){
      alert("Network Error 02");
      console.log(return_data);
      return false;
    }
    return return_data;
  }
  render() {
    return (
      <MyContext.Provider
        value={{
          version : this.state.version,
          session : this.state.session,
          setSession : (data) => {
            window.localStorage.setItem('session_fat_loss_with_arun_admin_app',JSON.stringify(data));
            this.setState({
              session : data
            });
          },
          bind_session : this.bind_session,
          validate_XHR_return_data : this.validate_XHR_return_data,
          session_bind2 : (ajax_parameters) => {
            var obj_this = this;
            // console.log(ajax_parameters);
            ajax_parameters.data = this.bind_session(ajax_parameters.data);
            var user_defined_success = ajax_parameters.success;
            ajax_parameters.success = function(return_data, textStatus, jqXHR){
              // console.log("Success keeri!!!");
              return_data = obj_this.validate_XHR_return_data(return_data);
              
              if(!return_data)//If errorn in return data
              return;

              user_defined_success(return_data, textStatus, jqXHR);
            };
            return ajax_parameters;
          },
          show_loading : this.state.show_loading,
          loading : (data) => {
            this.setState({
              show_loading : data
            });
          }
        }}
      >
      {this.props.children}
      </MyContext.Provider>
    );
  }
}