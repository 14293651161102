import React, { Component } from "react";
import "./ImageUpload.scss";
import $ from "jquery";

class ImageUpload extends Component{
    constructor(props){
        super(props);
        this.state={
            description:"",
            image:null,
            loading_popup_visibility:"none"

        };
        this.updateDescription=this.updateDescription.bind(this)
        this.submitImage=this.submitImage.bind(this)
        this.updateImage=this.updateImage.bind(this)
        this.imageRef=React.createRef();
    };  

    updateDescription(e){
        this.setState({
            description:e.target.value
        });
    }
    updateImage(e){
        this.setState({
            image:e.target.files[0]
        });
    }

    submitImage(e){
        this.setState({
            loading_popup_visibility:"block"
        });
        e.preventDefault();
        // return;

       const obj_this=this;
       if(this.state.description==""){
        alert("Please Enter Image Description");
        return;
       }

        //    console.log(this.state.description);
    
        // const formObj = new FormData();
        // formObj.append('title', this.state.description);
        // formObj.append('image', this.state.image);

        const formObj=new FormData();
        formObj.append('description',this.state.description);
        formObj.append('image',this.state.image);

        // return;
        $.ajax({

            url:process.env.REACT_APP_SSS + "/upload_image.php",
            method:"POST",
            data:formObj,
            processData: false,
            contentType: false,
        }).done(function(return_data){
            // console.log("hi");
            // console.log(return_data);
            try{
                return_data=JSON.parse(return_data)
                
            }catch(e){
                console.log(return_data);
                return;
            }
            obj_this.imageRef.current.value="";
            obj_this.setState({
                description:"" ,
                loading_popup_visibility:"none"
            });
            if(return_data.status=="success"){
                alert("Image uploaded successfully");
            }else{
                alert(return_data.status);
            }

        });
    }
    
    render(){
        return(
            <div className="imageupload_page_container">
               <form className="upload_block" onSubmit={this.submitImage}>
                    <input className="image_input" type="file" name="image" onChange={this.updateImage} required ref={this.imageRef} accept="image/*"></input>
                    <div className="input_container"></div>
                    <input className="description" placeholder="Image Description" value={this.state.description} onChange={this.updateDescription}></input>
                    <button className="upload_button" type="submit">Upload</button>
                </form> 
                <div className="loading_page_container" style={{display:this.state.loading_popup_visibility}}>
                    <div className="loading_container">
                        <div className="lctc" style={{ position: "fixed" }}>
                        <svg
                            width="69px"
                            height="69px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                            className="lds-rolling"
                            style={{
                            shapeRendering: "auto",
                            animationPlayState: "running",
                            animationDelay: "0s"
                            }}
                        >
                        <circle
                            cx={50}
                            cy={50}
                            fill="none"
                            ng-attr-stroke="{{config.color}}"
                            ng-attr-stroke-width="{{config.width}}"
                            ng-attr-r="{{config.radius}}"
                            ng-attr-stroke-dasharray="{{config.dasharray}}"
                            stroke="#6e6e6e"
                            strokeWidth={3}
                            r={35}
                            strokeDasharray="164.93361431346415 56.97787143782138"
                            style={{ animationPlayState: "running", animationDelay: "0s" }}
                            >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                calcMode="linear"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="0.3s"
                                begin="0s"
                                repeatCount="indefinite"
                                style={{ animationPlayState: "running", animationDelay: "0s" }}
                            />
                        </circle>
                        </svg>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default ImageUpload;