import { Component } from "react";
import "./Enquiries.scss";
import $ from "jquery";
import { Link } from "react-router-dom";



class Enquiries extends Component{
    constructor(props){
        super(props);
        this.state={
            enquiries:[]
        };
    }
    componentDidMount(){
        const obj_this=this;
        $.ajax({
            url:process.env.REACT_APP_SSS + "/enquiries_list.php"

        }).done(function(return_data){
            // console.log(return_data);
            try{
                return_data=JSON.parse(return_data);
            }
            catch(err){
                console.log(return_data);
                return;
            }
                
            console.log(return_data);

            obj_this.setState({
                enquiries:return_data
            })
        });
    }
    render(){
        return(
            <div className="enquiries_page_container">
                <div className="enquiries_container">
                    {this.state.enquiries.map((enquiry,idx)=>(
                        <Link to={"/ViewEnquiry/"+enquiry["Enquiry ID"]} className="single_enquiry_container" key={idx}
                        style={{backgroundImage:(enquiry.Status=="Unread")?"linear-gradient(to bottom right, red, green)":""}}
                        >
                            <div className="name">{enquiry.Firstname}</div>
                            <div className="name">{enquiry.Phone}</div>
                            <div className="name">{enquiry.Timestamp}</div>
                            <div className="name">{enquiry.Email}</div>
                        </Link>
                    ))}
                </div>
            </div>
        )
    }
}
export default Enquiries;